body{
    background:"#110e15";
    font-family: 'Poppins'

}

.max-w-1440 {
    max-width: 1440px;
    margin: 0 auto;
  }

  .hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

#scetch-board {
    position: relative;
    transform: translateY(0);
    animation: botMove 2s infinite ease;
}

@keyframes botMove {
    50%{
        transform: translateY(-50px);
    }
}

* {
    scrollbar-width: none; 
    -ms-overflow-style: none;  
  }
  
  *::-webkit-scrollbar {
    display: none; 
  }
.hide-scrollbar {
    overflow: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    display: none; 
  }
.custom-placeholder-input::placeholder {
  font-size: 12px;
}

.fullpagechat-container .custom-placeholder-input::placeholder {
  font-size: 12px;
}


